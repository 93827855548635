<template>
  <view-card-template :loaded="loaded">
    
    <template #cardTitle>
      Award Types
    </template>

    <template #cardBody>
      <div> <!-- Enclosing element is required to make the expanding table footer look right -->
        <v-row class="mx-1 mb-3">
          <v-col cols="4">
            <v-text-field
              v-model="titleSearch"
              label="Filter by Title"
              hide-details
              clearable
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="selectedGlobalSportId"
              :items="globalSports"
              item-text="name"
              item-value="globalSportId"
              label="Filter by Sport"
              hide-details
              clearable
            />
          </v-col>
          <v-col cols="4">
            <div
              class="d-flex align-center pt-2"
              style="height: 100%;"
            >
              <v-btn
                @click="openCreateModal"
                color="primary"
                hide-details
                block
              >
                Create Award Type
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="awardTypesFiltered"
          :items-per-page="-1"
          class="expanding-v-data-table"
          dense
        >

          <template v-slot:[`item.title`]="{ item }">
            <td class="font-weight-medium text-center">
              {{ item.title }}
            </td>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <td>
              <div class="d-flex justify-center align-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="openEditModal(item)"
                      color="blue darken-2"
                      icon
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  Edit Award Type
                </v-tooltip>
              </div>
            </td>
          </template>

          <template #footer="{ props }">
            <v-divider v-if="props.pagination.itemsLength > 0"/>
            <div class="expanding-v-data-table--footer"></div>
          </template>
        </v-data-table> 
      </div>

      <app-modal
        v-if="modal"
        :value="modal"
        :persistent="true"
        :header-text="modalHeaderText"
        width="auto"
        min-width="800px"
      >
        <template #content>
          <v-form ref="form">
            <v-row
              class="mt-3"
              no-gutters
            >
              <!-- Title -->
              <v-col cols="6">
                <div class="mx-2">
                  <v-text-field
                    v-model="modalAwardType.title"
                    label="Title"
                    :rules="[
                      (input) => { return (input != null && input.length > 0) || 'Required'}
                    ]"
                    clearable
                  />
                </div>
              </v-col>
              <!-- Sport -->
              <v-col cols="6">
                <div class="mx-2">
                  <v-select
                    v-model="modalAwardType.globalSportId"
                    :items="globalSportsComplete"
                    item-text="name"
                    item-value="globalSportId"
                    label="Sport"
                    :rules="[
                      (input) => { return input != null || 'Required'}
                    ]"
                    clearable
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="cancelModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="submitAwardType()"
            :loading="submitAwardTypeLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Submit
          </v-btn>
        </template>
      </app-modal>

    </template>
  </view-card-template>
</template>

<script>
import AppModal from "@/components/app/AppModal"
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from "@/data/controllers"
import GlobalSports from "@/data/GlobalSports"

export default {
  name: "AwardTypeManagement",

  components: {
    AppModal,
    ViewCardTemplate,
  },

  props: {
    //
  },

  data: () => ({
    loaded: false,

    titleSearch: "",

    selectedGlobalSportId: null,
    globalSports: [],
    globalSportsComplete: [],

    modal: false,
    modalHeaderText: "Create Award Type",
    modalAwardType: null,
    creating: false,

    submitAwardTypeLoading: false,
    
    awardTypes: [],

    headers: [
      {
        text: "Title",
        align: "center",
        width: "33%",
        sortable: false,
        value: "title",
      },
      {
        text: "Sport",
        align: "center",
        width: "33%",
        sortable: false,
        value: "sport.name",
      },
      {
        text: "Actions",
        align: "center",
        width: "33%",
        sortable: false,
        value: "actions",
      },
    ]
  }),

  computed: {
    awardTypesFiltered() {
      return this.awardTypes
        .filter(at => this.titleSearch == null || at.title.toLocaleLowerCase().includes(this.titleSearch.toLocaleLowerCase()))
        .filter(at => this.selectedGlobalSportId == null || at.globalSportId == this.selectedGlobalSportId)
    }
  },

  methods: {
    openCreateModal() {
      this.modalAwardType = {
        id: -1,
        title: null,
        globalSportId: null,
      }

      this.creating = true
      this.modalHeaderText = "Create Award Type"
      this.modal = true
    },

    openEditModal(awardType) {
      this.modalAwardType = {
        id: awardType.id,
        title: awardType.title,
        globalSportId: awardType.globalSportId == "Generic" ? null : awardType.globalSportId,
      }

      this.creating = false
      this.modalHeaderText = "Edit Award Type"
      this.modal = true
    },

    cancelModal() {
      this.modalAwardType = null
      this.modal = false
    },

    async submitAwardType() {
      if(this.submitAwardTypeLoading) return
      if(!this.$refs.form.validate()) return
      this.submitAwardTypeLoading = true

      const awardTypeSubmission = {
        ...this.modalAwardType,
        globalSportId: this.modalAwardType.globalSportId == -42 ? null : this.modalAwardType.globalSportId
      }

      if(this.creating) {
        const res = await Controllers.AwardTypeController.SupportCreateAwardType(awardTypeSubmission)
        if(res && !res.hasError) {
          const awardType = res.data
          
          if(awardType.globalSportId == -1) {
            // Hidden
            awardType.sport = { globalSportId: -1, name: "Hidden" }
          }
          else if(awardType.globalSportId == null) {
            // Generic
            awardType.sport = { globalSportId: -42, name: "Generic" }
            awardType.globalSportId = -42
          }
          else {
            // Standard case
            awardType.sport = GlobalSports.find(s => s.globalSportId == awardType.globalSportId)
          }

          this.awardTypes.push(awardType)
          this.$root.showSnackbar('Award Type created successfully!', "success", 5000)
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to create Award Type.', "error", 5000)
        }
      }
      else {
        const res = await Controllers.AwardTypeController.SupportEditAwardType(awardTypeSubmission)

        if(res && !res.hasError) {
          const awardType = res.data

          const listAwardType = this.awardTypes.find(at => at.id == awardType.id)
          listAwardType.title = awardType.title
          listAwardType.globalSportId = awardType.globalSportId

          if(awardType.globalSportId == -1) {
            // Hidden
            listAwardType.sport = { globalSportId: -1, name: "Hidden" }
          }
          else if(awardType.globalSportId == null) {
            // Generic
            listAwardType.sport = { globalSportId: -42, name: "Generic" }
            listAwardType.globalSportId = -42
          }
          else {
            // Standard case
            listAwardType.sport = GlobalSports.find(s => s.globalSportId == awardType.globalSportId)
          }

          this.$root.showSnackbar('Award Type edited successfully!', "success", 5000)
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to edit Award Type.', "error", 5000)
        }
      }

      this.submitAwardTypeLoading = false
      this.cancelModal()
    },
  },

  async created() {
    this.$root.updateTitle("Award Types")

    const res = await Controllers.AwardTypeController.SupportListAwardTypes()
    if(res && !res.hasError) {
      const awardTypes = res.data
      
      const sportNamesByGlobalSportId = {}
      awardTypes.forEach(at => {
        // Save the associated sport, or generic/hidden if required.
        if(at.globalSportId != null) {
          if(at.globalSportId == -1) {
            // Hidden
            at.sport = { globalSportId: -1, name: "Hidden" }
          }
          else {
            // Standard case
            at.sport = GlobalSports.find(s => s.globalSportId == at.globalSportId) 
            sportNamesByGlobalSportId[at.globalSportId] = at.sport
          } 
        }
        else {
          // Generic (-42 used for v-select, convert back to null in API calls)
          at.sport = { globalSportId: -42, name: "Generic" }
          at.globalSportId = -42
        }
      })

      this.globalSports = Object.values(sportNamesByGlobalSportId).sort((a, b) => a.globalSportid - b.globalSportId)
      this.globalSports.unshift({ globalSportId: -1, name: "Hidden" })
      this.globalSports.unshift({ globalSportId: -42, name: "Generic" })

      this.globalSportsComplete =
        [
          { globalSportId: -42, name: "Generic" },
          { globalSportId: -1, name: "Hidden" },
          ...GlobalSports
        ]
        .sort((a, b) => a.globalSportId - b.globalSportId)

      this.awardTypes = awardTypes.sort((a, b) => a.globalSportId - b.globalSportId)
    }
    else {
      // Error handling
    }

    this.loaded = true
  },
}
</script>

<style>

</style>